import Vue from "vue";
import Router from "vue-router";
import NProgress from "nprogress";
import smoothscroll from "smoothscroll-polyfill";
import store from "@/store/index";
import i18n from './i18n';

smoothscroll.polyfill();
Vue.use(Router);
const router = new Router({
  mode: "history",
  scrollBehavior: async (to, from, savedPosition) => {
    if (savedPosition) {
      return savedPosition;
    }

    const findEl = async (hash, x) => {
      return document.querySelector(hash) ||
        new Promise((resolve) => {
          if (x > 50) {
            return resolve();
          }
          setTimeout(() => {
            resolve(findEl(hash, ++x || 1))
          }, 100)
        })
    }

    if (to.hash) {
      const el = await findEl(to.hash)
      console.log(el.offsetTop)

      if ("scrollBehavior" in document.documentElement.style) {
        return window.scrollTo({
          top: el.offsetTop,
          behavior: "smooth"
        })
      } else {
        return window.scrollTo(0, el.offsetTop);
      }
    }

    return {
      x: 0,
      y: 0
    }
  },
  routes: [{
    path: "/",
    name: "login",
    meta: { requiresAuth: false },
    component: () => import( /* webpackChunkName: "front" */ "./views/front/Page.vue")
  },{
    path: "/login_as",
    name: "login_as",
    meta: { requiresAuth: false },
    component: () => import( /* webpackChunkName: "front" */ "./views/front/LoginAs.vue")
  },
  {
    path: "/verify",
    name: "verify_email",
    meta: { requiresAuth: false },
    component: () => import( /* webpackChunkName: "front" */ "./views/accounts/VerifyEmail.vue"),
    props: true,
    beforeEnter(routeTo, routeFrom, next) {
      const link_code = new URLSearchParams(routeTo.query).get('verification_link_code'),
        lang = localStorage.selectLang || i18n.locale,
        params = `verification_link_code=${link_code}&lang=${lang}`
      console.log(params)
      store
        .dispatch('auths/verifyAction', params)
        .then(response => {
            routeTo.params.verify = response
            next();
        }).catch(error => {
          if (error.response && error.response.status == 404) {
            next({
              name: "404"
            })
          } else {
            next({
              name: "422"
            })
          }
        })
    }
  },
  {
    path: "/legal/terms",
    name: "memberterms",
    meta: { requiresAuth: false },
    component: () => import( /* webpackChunkName: "front" */ "./views/legal-documents/TermsTxt.vue")
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    meta: { requiresAuth: true },
    component: () => import( /* webpackChunkName: "top" */ "./views/dashboard/Dashboard.vue"),
    props: true,
    beforeEnter(routeTo, routeFrom, next) {
      store
        .dispatch("layout/getLayoutAction")
        .then(layout => {
          routeTo.params.layout = layout[0]
          next();
        })
        .catch(error => {
          if (error.response && error.response.status == 404) {
            next({
              name: "404"
            })
          } else {
            next({
              name: "422"
            })
          }
        })
    }
  },
  {
    path: "/guide",
    name: "guide",
    meta: { requiresAuth: true },
    component: () => import( /* webpackChunkName: "guide" */ "./views/tools/Guide.vue")
  },
  {
    path: "/faq",
    name: "faq",
    meta: { requiresAuth: true },
    component: () => import( /* webpackChunkName: "faq" */ "./views/tools/Faq.vue")
  },
  {
    path: "/mails",
    name: "mails",
    meta: { requiresAuth: true },
    component: () => import( /* webpackChunkName: "mails-group" */ "./views/mails/Inbox.vue"),
    props: true,
    beforeEnter(routeTo, routeFrom, next) {
      store
        .dispatch("mails/getMailsAction", routeTo.query)
        .then(mails => {
          routeTo.params.mails = mails
          next();
        })
        .catch(error => {
          if (error.response && error.response.status == 404) {
            next({
              name: "404"
            })
          } else {
            next({
              name: "422"
            })
          }
        })
    }
  },
  {
    path: "/mails/:id",
    name: "mail",
    meta: { requiresAuth: true },
    component: () => import( /* webpackChunkName: "mails-group" */ "./views/mails/Mail.vue"),
    props: true,
    beforeEnter(routeTo, routeFrom, next) {
      store
        .dispatch("mails/getMailAction", routeTo.params.id)
        .then(mail => {
          routeTo.params.mail = mail[0]
          next();
        })
        .catch(error => {
          if (error.response && error.response.status == 404) {
            next({
              name: "404"
            })
          } else {
            next({
              name: "422"
            })
          }
        })
    }
  },
  {
    path: "/accounts",
    name: "accounts",
    meta: { requiresAuth: true },
    component: () => import( /* webpackChunkName: "group-accounts" */ "./views/accounts/index.vue"),
    props: true,
    beforeEnter(routeTo, routeFrom, next) {
      store
        .dispatch("accounts/getProfileAction")
        .then(profile => {
          routeTo.params.profile = profile;
          console.log(profile);
          next();
        })
        .catch(error => {
          if (error.response && error.response.status == 404) {
            routeTo.error = error.response
            next({
              name: "404"
            })
          } else {
            routeTo.error = error.response
            next({
              name: "422"
            })
          }
        })
    }
  },
  {
    path: "/bank",
    name: "bank",
    meta: { requiresAuth: true },
    component: () => import( /* webpackChunkName: "group-accounts" */ "./views/accounts/payments/Bank.vue"),
  },
  {
    path: "/accounts/invoice_history",
    name: "invoices",
    meta: { requiresAuth: true },
    component: () => import( /* webpackChunkName: "group-accounts" */ "./views/accounts/invoices/Invoices.vue"),
    props: true,
    beforeEnter(routeTo, routeFrom, next) {
      store
        .dispatch("accounts/getInvoicesAction")
        .then(invoices => {
          routeTo.params.invoices = invoices
          next();
        })
        .catch(error => {
          if (error.response && error.response.status == 404) {
            next({
              name: "404"
            })
          } else {
            next({
              name: "422"
            })
          }
        })
    }
  },
  {
    path: "/accounts/invoice",
    name: "invoice",
    meta: { requiresAuth: true },
    component: () => import( /* webpackChunkName: "group-accounts" */ "./views/accounts/invoices/Invoice.vue"),
    props: true,
    
  },
  {
    path: "/sales",
    name: "sales",
    meta: { requiresAuth: true },
    component: () => import( /* webpackChunkName: "group-sales" */ "./views/sales/Sales.vue"),
    props: true,
    beforeEnter(routeTo, routeFrom, next) {
      console.log('sales', routeTo)
      let options = {
        from: routeTo.query.from || "",
        to: routeTo.query.to || ""
      }
      store
        .dispatch("sales/getSalesAction", options)
        .then(sales => {
          console.log(sales)
          routeTo.params.sales = sales
          next();
        })
        .catch(error => {
          if (error.response && error.response.status == 404) {
            next({
              name: "404"
            })
          } else {
            next({
              name: "422"
            })
          }
        })
      }
  },
  {
    path: "/sales/sites",
    name: "sitessales",
    meta: { requiresAuth: true },
    component: () => import( /* webpackChunkName: "group-sales" */ "./views/sales/Sites.vue"),
    props: true,
    beforeEnter(routeTo, routeFrom, next) {
      let options = {
        from: routeTo.query.from || "",
        to: routeTo.query.to || ""
      }
      store
        .dispatch("sales/getSitesSalesAction", options)
        .then(sales => {
          console.log(sales)
          routeTo.params.sales = sales
          next();
        })
        .catch(error => {
          if (error.response && error.response.status == 404) {
            next({
              name: "404"
            })
          } else {
            next({
              name: "422"
            })
          }
        })
      }
  },
  {
    path: "/sales/site/:siteid",
    name: "sitesales",
    meta: { requiresAuth: true },
    component: () => import( /* webpackChunkName: "group-sales" */ "./views/sales/Sales.vue"),
    props: true,
    beforeEnter(routeTo, routeFrom, next) {
      console.log('site_sale',routeTo)
      let options = {
        from: routeTo.query.from || "",
        to: routeTo.query.to || "",
        siteid: routeTo.params.siteid
      }
      store
        .dispatch("sales/getSiteSalesAction", options)
        .then(sales => {
          console.log(sales)
          routeTo.params.sales = sales
          next();
        })
        .catch(error => {
          if (error.response && error.response.status == 404) {
            next({
              name: "404"
            })
          } else {
            next({
              name: "422"
            })
          }
        })
      }
  },
  {
    path: "/sales/report/:type",
    name: "report",
    meta: { requiresAuth: true },
    component: () => import( /* webpackChunkName: "group-sales" */ "./views/sales/Report.vue"),
    props: true,
    beforeEnter(routeTo, routeFrom, next) {
      console.log(routeTo)
      const today = new Date();
      const year = today.getFullYear();
      const month = String(today.getMonth() + 1).padStart(2, '0');
      const day = String(today.getDate()).padStart(2, '0');
      const formattedToday = `${year}-${month}-${day}`;
      console.log('formattedToday',formattedToday)
      let options = {
        from: routeTo.query.from|| `${year}-${month}-01`,
        to: routeTo.query.to|| formattedToday,
        type: routeTo.params.type,
        lang: localStorage.selectLang,
        page: routeTo.query.page || 1,
        limit: routeTo.query.limit || 25,
      }
      console.log(options)
      store
        .dispatch("sales/getReportAction", options)
        .then(report => {
          routeTo.params.report = report
          next();
        })
        .catch(error => {
          if (error.response && error.response.status == 404) {
            next({
              name: "404"
            })
          } else {
            next({
              name: "422"
            })
          }
        })
    }
  },
  {
    path: "/sales/users/:userkey",
    name: "user",
    meta: { requiresAuth: true },
    component: () => import( /* webpackChunkName: "group-sales" */ "./views/sales/User.vue"),
    props: true,
    beforeEnter(routeTo, routeFrom, next) {
      console.log('user',routeTo)
      const userKey = store.state.sales.userKey
      console.log('Retrieved userKey from store:', userKey); 
      store
        .dispatch("sales/getUserAction", {
          userkey: userKey,
          lang: localStorage.selectLang,
          page: routeTo.query.page || 1,
          limit: routeTo.query.limit || 25,
        })
        .then(user => {
          routeTo.params.user = user,
          routeTo.params.userKey = userKey;
          next();
        })
        .catch(error => {
          if (error.response && error.response.status == 404) {
            next({
              name: "404"
            })
          } else {
            next({
              name: "422"
            })
          }
        })
    }
  },
  {
    path: "/ads",
    name: "ads",
    meta: { requiresAuth: true },
    component: () =>
      import( /* webpackChunkName: "group-ads" */ "./views/ads/Ads.vue"), 
    props: { siteid: null }
  },
  {
    path: "/ads/:siteid?",
    name: "siteads",
    meta: { requiresAuth: true },
    component: () =>
      import( /* webpackChunkName: "group-ads" */ "./views/ads/Ads.vue"),
    props: true
  },
  {
    path: '/ads/:siteid/:bannerid',
    name: 'ad',
    meta: { requiresAuth: true },
    component: () =>
      import( /* webpackChunkName: "group-ads" */ './views/ads/Ad.vue'),
    props: true   
  },
  {
    path: "/adtools/cbanners",
    name: "cbanners",
    meta: { requiresAuth: true },
    component: () =>
      import( /* webpackChunkName: "group-adtools" */ "./views/adtools/dbanners/CreateBanner.vue"),
  },
  {
    path: "/adtools/dynamic",
    name: "dynamic",
    meta: { requiresAuth: true },
    component: () =>
      import( /* webpackChunkName: "group-adtools" */ "./views/adtools/dbanners/DynamicBanner.vue"),
  },
  {
    path: "/adtools/gallery",
    name: "gallery",
    meta: { requiresAuth: true },
    component: () =>
      import( /* webpackChunkName: "group-adtools" */ "./views/adtools/dbanners/SnsGallery.vue"),
  },
  {
    path: "/adtools/movielist",
    name: "movielist",
    meta: { requiresAuth: true },
    component: () =>
      import( /* webpackChunkName: "group-adtools" */ "./views/adtools/tools/MovieList.vue"),
  },
  {
    path: "/adtools/bmaker",
    name: "bmaker",
    meta: { requiresAuth: true },
    component: () =>
      import( /* webpackChunkName: "group-adtools" */ "./views/adtools/dbanners/maker/BannerMaker.vue"),
  },
  {
    path: "/adtools/pinktok",
    name: "pinktok",
    meta: { requiresAuth: true },
    component: () =>
      import( /* webpackChunkName: "group-adtools" */ "./views/adtools/pinktok/Page.vue"),
  },
  {
    path: "/adtools/links",
    name: "links",
    meta: { requiresAuth: true },
    component: () =>
      import( /* webpackChunkName: "group-adtools" */ "./views/adtools/tools/Link.vue"),
  },
  {
    path: "/adtools/api",
    name: "api",
    meta: { requiresAuth: true },
    component: () =>
      import( /* webpackChunkName: "group-adtools" */ "./views/adtools/tools/Api.vue"),
  },
  {
    path: "/adtools/cheatsheet",
    name: "cheatsheet",
    meta: { requiresAuth: true },
    component: () =>
      import( /* webpackChunkName: "group-adtools" */ "./views/adtools/tools/AdTips.vue"),
  },
  {
    path: "/adtools/pf",
    name: "pf",
    meta: { requiresAuth: true },
    component: () => import( /* webpackChunkName: "group-adtools" */ "./views/adtools/dbanners/Performer.vue"),
    props: true,
    beforeEnter(routeTo, routeFrom, next) {
      store
        .dispatch("legals/getPfAction")
        .then(pf => {
          routeTo.params.pf = pf
          next();
        })
        .catch(error => {
          if (error.response && error.response.status == 404) {
            next({
              name: "404"
            })
          } else {
            next({
              name: "422"
            })
          }
        })
    }
  },
  {
    path: "/adtools/policy",
    name: "policy",
    meta: { requiresAuth: true },
    component: () => import( /* webpackChunkName: "group-adtools" */ "./views/legal-documents/Policy.vue")
  },
  {
    path: "/sites",
    name: "sites",
    meta: { requiresAuth: true },
    component: () =>
      import( /* webpackChunkName: "group-sites" */ "./views/sites/Sites.vue"),

  },
  {
    path: "/sites/:siteid",
    name: "site",
    meta: { requiresAuth: true },
    component: () =>
      import( /* webpackChunkName: "group-sites" */ "./views/sites/Site.vue"),
    props: true,
  },
  {
    path: "/terms",
    name: "terms",
    meta: { requiresAuth: true },
    component: () =>
      import( /* webpackChunkName: "group-sites" */ "./views/legal-documents/Terms.vue"),
    props: true,
  },
  {
    path: "/privacy",
    name: "privacy",
    component: () =>
      import( /* webpackChunkName: "group-sites" */ "./views/legal-documents/Privacy.vue"),
    props: true,
  },
  {
    path: "/hrp",
    name: "hrp",
    meta: { requiresAuth: true },
    component: () =>
      import( /* webpackChunkName: "group-sites" */ "./views/legal-documents/HumanRightPolicy.vue"),
    props: true,
  },
  {
    path: "/bonusprograms",
    name: "bppage",
    meta: { requiresAuth: true },
    component: () => import( /* webpackChunkName: "studyprograms-status" */ "./views/bonusprograms/index_new.vue"),
    props: true,
    beforeEnter(routeTo, routeFrom, next) {
      store
        .dispatch('accounts/getStudyPlanAction')
        .then(splan => {
          routeTo.params.splan = splan
          next();
        })
        .catch(error => {
          if (error.response && error.response.status == 404) {
            next({
              name: "404"
            })
          } else {
            next({
              name: "422"
            })
          }
        })
    }
  },
  {
    path: "/logoutFailed",
    name: "logoutFailed",
    component: () =>
      import( /* webpackChunkName: "group-error" */ "./views/errors/logoutFailed.vue")
  },
  {
    path: "/canceled",
    name: "canceled",
    component: () =>
      import( /* webpackChunkName: "group-error" */ "./views/errors/canceled.vue")
  },
  {
    path: "/401",
    name: "401",
    component: () =>
      import( /* webpackChunkName: "group-error" */ "./views/errors/401.vue")
  },
  {
    path: "/404",
    name: "404",
    component: () =>
      import( /* webpackChunkName: "group-error" */ "./views/errors/404.vue")
  },
  {
    path: "/422",
    name: "422",
    meta: { requiresAuth: true },
    component: () =>
      import( /* webpackChunkName: "group-error" */ "./views/errors/422.vue")
  },
  {
    path: "/500",
    name: "500",
    meta: { requiresAuth: true },
    component: () =>
      import( /* webpackChunkName: "group-error" */ "./views/errors/500.vue")
  },
  {
    path: "/504",
    name: "504",
    meta: { requiresAuth: true },
    component: () =>
      import( /* webpackChunkName: "group-error" */ "./views/errors/504.vue")
  },
  {
    path: "*",
    meta: { requiresAuth: true },
    component: () =>
      import( /* webpackChunkName: "group-error" */ "./views/errors/404.vue"),
  }
  ]
})
let intendedRoute = null;

router.beforeEach((routeTo, routeFrom, next) => {
  if (routeTo.path === '/401' || routeTo.path === '/canceled' || routeTo.path === '/verify' || routeTo.name === 'login') {
    localStorage.clear();
    next();
  } else if (routeTo.meta.requiresAuth && !localStorage.getItem('csrf')) {
    next({
      name: 'login',
      query: { redirect: routeTo.fullPath }
    });
  } else {
    NProgress.start();
    next();
  }
});

router.afterEach(() => {
  NProgress.done();
});

router.onError((error) => {
  if (/Loading chunk \d+ failed/.test(error.message)) {
    window.location.reload();
  }
});

const originalPush = Router.prototype.push;
Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => {
    if (err.name !== 'NavigationDuplicated') {
      throw err;
    }
  });
};

export default router